<template>
	<div class="home_news">
		<div class="home_news_top">
			<div class="home_news_title">NEWS<br>新闻动态</div>
			<div class="home_news_top_con">
				<van-image class="home_news_img" width="100%" height="100%" fit="cover" lazy-load :src="$import('news.jpg')" />
				<span class="home_news_more" @click="moreClick">查看更多</span>
			</div>
		</div>

		<div class="home_news_list">
			<div v-for="(item, index) in list" :key="index" class="home_news_item" @click="itemClick(item, index)">
				<span class="home_news_item_time">{{ formatTime(item.create_time) }}</span>
				<p class="home_news_item_title">{{ item.title }}</p>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "index",
		props: {
			data: {
				type: Array,
				default() {
					return []
				}
			}
		},
		data() {
			return {
				list: this.data
			}
		},
		watch: {
			data: {
				deep: true,
				handler(val) {
					this.list = val;
				}
			}
		},
		methods: {
			formatTime(time) {
				let mm = time.substr(4, 2);
				let dd = time.substr(6, 2);
				return mm + '/' + dd
			},

			moreClick() {
				this.$router.push('/news');
			},

			itemClick(item, index) {
				this.$router.push('/news/' + item.content_id);
			}
		}
	}
</script>

<style lang="less" scoped>
	@import '~@/assets/fonts/fonts.css';

	.home_news {
		width: 100%;
		max-width: 100rem;
		margin: 0 auto;
		padding-bottom: 9rem;
	}

	.home_news_top {
		width: 100%;
		height: 16.8rem;
		display: flex;
		overflow: hidden;
	}

	.home_news_title {
		flex-shrink: 0;
		width: 24rem;
		font-size: 3.8rem;
		color: #ffffff;
		background-color: @greenLightColor;
		display: flex;
		justify-content: center;
		align-items: center;
		font-family: 'shangshushui';
	}

	.home_news_top_con {
		width: 100%;
		position: relative;
	}

	.home_news_more {
		width: 22.4rem;
		position: absolute;
		bottom: 0;
		right: 0;
		background-color: @greenDarkColor;
		color: #ffffff;
		font-size: 2rem;
		line-height: 3rem;
		text-align: center;
		cursor: pointer;
	}

	.home_news_list {
		padding-top: 2rem;
		background-color: #ffffff;
	}

	.home_news_item {
		padding: 2.5rem 5.5rem;
		display: flex;
		align-items: center;
		overflow: hidden;
		color: @blackColor;
		cursor: pointer;
		font-size: 2.4rem;
	}

	.home_news_item:not(:last-of-type) {
		border-bottom: 1px solid rgba(112, 213, 93, .3);
	}

	.home_news_item_time {
		flex-shrink: 0;
		width: 11rem;
		margin-right: 2rem;
		font-family: 'shangshushui';
		position: relative;
		top: 2px;
	}

	.home_news_item_title {
		.ellipsis;
	}






	@media screen and (max-width: 800px){
		.home_news {
			width: 31.8rem;
			padding-bottom: 3.5rem;
		}

		.home_news_top {
			height: 5.3rem;
		}

		.home_news_title {
			width: 7.5rem;
			font-size: 1.3rem;
		}

		.home_news_more {
			width: 7rem;
			font-size: 1rem;
			line-height: 1.5rem;
		}

		.home_news_list {
			padding-top: 0;
		}

		.home_news_item {
			background-color: #def5d9;
			line-height: 2.5rem;
			font-size: 1.2rem;
			padding: 0 2rem;
			margin-top: 0.8rem;
		}

		.home_news_item:not(:last-of-type) {
			border-bottom: none;
		}

		.home_news_item_time {
			width: 4rem;
			margin-right: 1rem;
			top: 0.1rem;
			font-family: normal;
		}
	}
</style>