<template>
	<div class="home_product">
		<div class="home_product_title">
			<p class="home_product_title1">产品中心</p>
			<p class="home_product_title2">PRODUCT CENTER</p>
		</div>

		<div class="home_product_con">
			<div class="home_product_list">
				<div v-for="(item, index) in list" :key="index" class="home_product_item" @click="itemClick(item, index)">
					<van-image class="home_product_item_img" width="100%" height="100%" fit="cover" lazy-load :src="item.product_img" />
					<div class="home_product_item_con">
						<span class="home_product_item_title">{{ item.product_name }}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "index",
		props: {
			data: {
				type: Array,
				default() {
					return []
				}
			}
		},
		data() {
			return {
				list: this.data
			}
		},
		watch: {
			data: {
				deep: true,
				handler(val) {
					this.list = val;
				}
			}
		},
		methods: {
			itemClick(item, index) {
				this.$router.push('/product/' + item.product_id);
			}
		}
	}
</script>

<style lang="less" scoped>
	@import '~@/assets/fonts/fonts.css';

	.home_product {
		padding: 10rem 0 9rem;
	}

	.home_product_title {
		text-align: center;
		color: @greenColor;
		padding-bottom: 4rem;
	}

	.home_product_title1 {
		font-size: 5rem;
	}

	.home_product_title2 {
		font-size: 1.5rem;
		margin-top: 0.5rem;
	}

	.home_product_con {
		width: 100%;
		height: 72rem;
		display: flex;
		justify-content: center;
		align-items: center;
		background-image: url("~@/assets/home_bg.jpg");
		background-size: 164rem 100%;
		background-repeat: no-repeat;
		background-position: center;
	}

	.home_product_list {
		display: flex;

	}

	.home_product_item {
		width: 32.8rem;
		height: 32.8rem;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		background-color: #ffffff;
	}

	.home_product_item_img {
		padding-top: 1.5rem;
		overflow: hidden;
	}

	.home_product_list .home_product_item:not(:first-of-type) {
		margin-left: 1rem;
	}

	.home_product_item:first-of-type .home_product_item_img {
		padding-left: 1.5rem;
	}

	.home_product_item:last-of-type .home_product_item_img {
		padding-right: 1.5rem;
	}

	.home_product_item_con {
		flex-shrink: 0;
		width: 100%;
		height: 10rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 0 2rem;
		text-align: center;
	}

	.home_product_item_title {
		width: 100%;
		font-size: 4rem;
		color: @greenDarkColor;
		font-family: 'shangshushui';
		.ellipsis;
	}

	.home_product_item_desc {
		width: 100%;
		font-size: 1.8rem;
		color: @blackColor;
		.ellipsis;
	}






	@media screen and (max-width: 800px){
		.home_product {
			padding-top: 2rem;
			padding-bottom: 4rem;
		}

		.home_product_title {
			padding-bottom: 1.1rem;
		}

		.home_product_title1 {
			font-size: 1.9rem;
		}

		.home_product_title2 {
			font-size: 0.6rem;
			margin-top: 0.2rem;
		}

		.home_product_con {
			width: 100%;
			height: 18rem;
			background-size: 100% 100%;
		}

		.home_product_item {
			width: 10.4rem;
			height: 10.4rem;
		}

		.home_product_item_con {
			height: 3.1rem;
		}

		.home_product_item_img {
			padding-top: 0.5rem;
		}

		.home_product_list .home_product_item:not(:first-of-type) {
			margin-left: 0.4rem;
		}

		.home_product_item:first-of-type .home_product_item_img {
			padding-left: 0.5rem;
		}

		.home_product_item:last-of-type .home_product_item_img {
			padding-right: 0.5rem;
		}

		.home_product_item_title {
			font-size: 1.3rem;
		}

		.home_product_item_desc {
			font-size: 0.8rem;
			margin-top: 0.2rem;
		}
	}
</style>