<template>
	<div>
		<div class="home_bg1">
			<product :data="productList"></product>

			<news :data="contentList"></news>
		</div>

		<about :data="aboutData"></about>
	</div>
</template>

<script>
	import Product from './product'
	import News from './news'
	import About from './about'

	export default {
		name: "index",
		components: {
			Product,
			News,
			About
		},
		data() {
			return {
				productList: [],
				contentList: [],
				aboutData: {}
			}
		},
		created(){
			this.init();
		},
		methods: {
			async init() {
				this.$loading();
				const res = await this.$api.home();
				this.$loading().clear();
				const data = res.data ? res.data : {};

				this.productList = data.productList;
				this.contentList = data.contentList;
				this.aboutData = data.config;
			}
		}
	}
</script>

<style lang="less" scoped>
	.home_bg1 {
		position: relative;
		overflow: hidden;
	}

	.home_bg1::after {
		content: "";
		width: 100%;
		height: 86rem;
		background-color: #eff9ee;
		position: absolute;
		top: 80rem;
		left: 0;
		z-index: -1;
	}
</style>
